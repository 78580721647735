<template>
  <div class='flex flex-col lg:flex-row min-h-screen'>
    <div class='w-full lg:w-1/3 px-4 lg:px-16 py-4 text-gray-50 flex flex-col items-center justify-center relative'
         :style='`background-color: ${eventMainThemeColor}`'>
      <div>
        <h1 class='text-3xl font-medium mb-4'>Registration Completed</h1>
      </div>
      <div class='absolute bottom-2 text-center'>
        <button
          class='text-xs text-gray-50 hover:shadow-md hover:underline'
          @click='logoutApp'>Logout
        </button>
      </div>
    </div>
    <div class='w-full lg:w-2/3 px-4 lg:px-16 py-4 text-gray-600 flex flex-col justify-center'
         style="min-height: calc(100vh - 5rem);">
      <h1 class='text-2xl font-semibold mb-12'>Poster Upload Completed</h1>     
      <div>Please contact us when you need to modify your poster information</div>
      <a href='mailto:support@webconcert.co.kr'  class='mb-4 underline'>support@webconcert.co.kr</a>
      <button class='underline text-sm text-left w-40' @click='goToPosterUpload'>back to upload</button>
    </div>
  </div>

</template>

<script>
import {mapGetters, mapActions}          from 'vuex'

export default {
  name: 'PosterUploadCompleted',
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor'
    ]),
  },
  methods: {
    goToPosterUpload () {
      this.$router.push({name: 'PosterUpload'})
    },
    ...mapActions('users', [
      'logout'
    ]),
    logoutApp () {
      this.logout().then(() => {
        this.$router.push({name: 'Login'})
      })
    }
  },
  mounted () {
    this.$emit('update:layout', 'empty-page')
  },
  beforeDestroy () {
    this.$emit('update:layout', 'default-layout')
  },
}
</script>
